import * as React from "react";
import AssetPage from "../../components/asset-page";

const ArbitrumPage = () => (
  <AssetPage
    asset="Arbitrum"
    ticker="ARB"
    description={[
      `As decentralized applications (dApps) and smart contracts continue to shape the digital landscape, Ethereum faces its own set of scalability hurdles. Addressing this is Arbitrum, a renowned layer-2 scaling solution utilizing Optimistic Rollups to augment Ethereum's transaction capabilities while considerably reducing gas expenses. With a dedication to amplifying Ethereum's efficiency, Arbitrum stands as a beacon for dApps aiming for performance without hefty costs.`,
      `Arbitrum's emergence marks a pivotal moment in tackling Ethereum's inherent scalability issues. By seamlessly integrating cutting-edge rollup technology, maintaining compatibility with Ethereum, and offering reinforced security, Arbitrum is set to be a cornerstone in Ethereum's dApp ecosystem evolution. For those in search of performance, scalability, and security, Arbitrum shines brightly in the layer-2 arena.`,
    ]}
    hideFeatureImage
    features={[
      {
        title: "Optimistic Rollups",
        text: `Central to Arbitrum's architecture are Optimistic Rollups. This technology aggregates multiple transactions into a singular proof, subsequently posted to the Ethereum mainnet. This consolidation quickens transaction execution and substantially trims gas charges, enhancing the overall dApp user experience in terms of speed and cost.`,
      },
      {
        title: "Ethereum Compatibility",
        text: "Arbitrum's beauty lies in its innate compatibility with Ethereum's infrastructure and developer toolkits. Developers can effortlessly migrate their dApps onto Arbitrum with minimal adjustments, facilitating a seamless transition. This compatibility fosters faster integration and widespread layer-2 adoption within the expansive Ethereum community.",
      },
      {
        title: "DApps Ecosystem",
        text: "Arbitrum's efficient scaling solution has attracted a diverse range of dApps, from DeFi platforms to games. With reduced transaction fees and quicker confirmation times, dApp developers and users alike find Arbitrum an appealing environment. The flourishing dApp ecosystem on Arbitrum testifies to its promise as a hub for next-generation decentralized applications.",
      },
      {
        title: "Decentralized Security Model",
        text: "Arbitrum prides itself on a security model that doesn't compromise, even with the promise of scalability. While many operations are off-chain, the foundation and transaction finality are deeply anchored in Ethereum's decentralized security ethos. Users and developers can have confidence in the integrity of their assets and transactions, fortified by Ethereum's established security mechanisms.",
      },
    ]}
  />
);


export default ArbitrumPage;
